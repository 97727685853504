<template>
  <section :id="id">
    <v-row no-gutters>
      <v-col cols="12">
        <component
          :is="`section-${section}`"
          v-for="section in sections"
          :key="section"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'BaseView',

    props: {
      id: {
        type: String,
        default: 'view',
      },
    },
    data: () => ({ sections: [] }),
    created () {
      this.updateLoading(true)
    },
    mounted () {
      const updateFunction = this.updateLoading
      this.$nextTick(function () {
        setTimeout(function () { updateFunction(false) }, 300)
      })
    },
    methods: {
      ...mapActions(['updateLoading']),
    },
  }
</script>
