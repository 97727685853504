const getRoutingDefaultState = () => {
    return {
        loading: false,
    }
}
const state = getRoutingDefaultState()

const getters = {
    isLoading: state => state.loading,
}

const actions = {
    updateLoading ({ commit }, loading) {
        commit('setLoading', loading)
    },
}

const mutations = {
    setLoading (state, loading) {
        state.loading = loading
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
