// Imports
import Vue from 'vue'
import Router from 'vue-router'
import HomeLayout from '@/layouts/home/Index.vue'
import HomeView from '@/views/home/Index.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: HomeLayout,
      children: [
        {
          path: '',
          name: 'Home',
          component: HomeView,
        },
        {
          path: 'jazz',
          name: 'Jazz',
          component: () => import('@/views/jazz/Index.vue'),
        },
        {
          path: 'trails',
          name: 'Trails',
          component: () => import('@/views/trails/Index.vue'),
        },
        {
          path: 'discover',
          name: 'Discover',
          component: () => import('@/views/discover/Index.vue'),
        },
        {
          path: 'aToz',
          name: 'A To Z',
          component: () => import('@/views/aToZ/Index.vue'),
        },
        {
          path: 'about',
          name: 'About',
          component: () => import('@/views/about/Index.vue'),
        },
        {
          path: 'contact-us',
          name: 'Contact',
          component: () => import('@/views/contact-us/Index.vue'),
        },
        {
          path: 'privacy',
          name: 'Privacy',
          component: () => import('@/views/legal/Privacy.vue'),
        },
        {
          path: 'cookies',
          name: 'Cookies',
          component: () => import('@/views/legal/Cookies.vue'),
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
