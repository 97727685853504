import Vue from 'vue'
import Vuex from 'vuex'
import trails from '@/store/modules/trails.js'
import routing from '@/store/modules/routing.js'
import gigs from '@/store/modules/gigs.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    trails,
    routing,
    gigs,
  },
})
