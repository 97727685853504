function applyFilter (filter, pubs) {
    const filteredPubs = []
    for (const pub of pubs) {
        if (pub[filter.key] === filter.value) {
            filteredPubs.push(pub)
        }
    }
    return filteredPubs
}

const getTrailsDefaultState = () => {
    return {
        trails: [],
        pubs: [],
        selectedTrail: null,
        dayBanner: null,
        nightBanner: null,
        pubFilters: [],
        filteredPubs: [],
    }
}
const state = getTrailsDefaultState()

const getters = {
    getSelectedTrail: state => state.selectedTrail ? state.selectedTrail : null,
    getTrailList: state => state.trails,
    getSelectedTrailId: state => state.selectedTrail ? state.selectedTrail.id : -1,
    getPubList: state => state.pubs.filter(pub => !pub.closed),
    getPubFilters: state => state.pubFilters,
    alphaBeticalPubs: state => state.pubs.filter(pub => !pub.closed).sort(function (a, b) {
        var textA = a.name.toUpperCase()
        var textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    }),
    getFilteredPubs: state => state.filteredPubs,
    getPubsFromSelectedTrail: state => state.selectedTrail == null ? state.pubs : state.pubs.filter(pub => state.selectedTrail.pubs.indexOf(pub.id) > -1),
    getDayBannerImage: state => state.dayBanner,
    getNightBannerImage: state => state.nightBanner,
}

const actions = {
    async fetchTrailsAndPubs({ commit }) {
        try {
            // Fetch the trails.json
            const trailsResponse = await fetch('/data/trails.json');
            const trails = await trailsResponse.json();

            // Fetch the pubs.json
            const pubsResponse = await fetch('/data/pubs.json');
            const pubs = await pubsResponse.json();

            // Commit the data to Vuex state
            commit('updateTrailsJson', trails);
            commit('updatePubJson', pubs);
        } catch (error) {
            console.error('Error loading JSON files:', error);
        }
    },
    updateSelectedTrail ({ commit }, selectedTrail) {
        commit('updateSelectedTrail', selectedTrail)
    },
    addBannerImages ({ commit }, banners) {
        commit('updateBanners', banners)
    },
    filterPubs ({ commit, dispatch }, filters) {
        commit('updateFilters', filters)
        if (filters == null) {
            commit('updateFilteredPubs', [])
        } else {
            let filteredPubs = [...state.pubs]
            const activeFilters = []
            for (const [key, value] of Object.entries(filters)) {
                if (value !== 2) {
                    activeFilters.push({ key: key, value: value })
                }
            }
            if (activeFilters.length > 0) {
                for (const filter of activeFilters) {
                    filteredPubs = applyFilter(filter, filteredPubs)
                }
            } else {
                filteredPubs = state.pubs
            }
            commit('updateFilteredPubs', filteredPubs)
        }
    },
}

const mutations = {
    updatePubJson (state, json){
        state.pubs = json;
    },
    updateTrailsJson(state, json){
        state.trails = json;
    },
    updateSelectedTrail (state, selectedTrail) {
        state.selectedTrail = selectedTrail
    },
    updateBanners (state, banners) {
        state.dayBanner = banners.dayBanner
        state.nightBanner = banners.nightBanner
    },
    updateFilters (state, filters) {
        state.pubFilters = filters
    },
    updateFilteredPubs (state, pubs) {
        state.filteredPubs = pubs
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
