<template>
  <v-img
    :position="imagePosition"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-img>
</template>

<script>
  export default {
    name: 'BaseImage',
    props: {
      imagePosition: {
        type: String,
        default: 'center center',
      },
    },
  }
</script>
