<template>
  <div
    class="d-flex align-center"
    :style="[clickable ? {'cursor' : 'pointer'} : '']"
  >
    <v-icon
      v-if="icon"
      :color="iconColour"
      class="mr-3"
      size="28"
      v-text="icon"
    >
      {{ icon }}
    </v-icon>

    <div
      v-if="text"
      class="text-subtitle-1"
      v-text="text"
    />
  </div>
</template>

<script>
  export default {
    name: 'BaseListItem',

    props: {
      color: {
        type: String,
        default: 'primary',
      },
      icon: {
        type: String,
        default: 'mdi-check-circle-outline',
      },
      selected: {
        type: Boolean,
        default: false,
      },
      clickable: {
        type: Boolean,
        default: false,
      },
      text: String,
    },
    computed: {
      iconColour () {
        return !this.selected ? this.color : this.$vuetify.theme.isDark ? '#FFFFFF' : '#000000'
      },
    },
  }
</script>
