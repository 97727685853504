<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'App',
    metaInfo: {
      title: 'Trails',
      titleTemplate: '%s | Cork',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    mounted () {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.$vuetify.theme.dark = true
      }
    },
    created () {
      const nightBanner = require('@/assets/shandon_banner_night-min.jpg')
      const dayBanner = require('@/assets/shandon_banner_day-min.jpg')
      var dayImage = new Image()
      var nightImage = new Image()
      dayImage.src = dayBanner
      nightImage.src = nightBanner
      this.addBannerImages({ dayBanner: dayImage, nightBanner: nightImage })
    },
    methods: {
      ...mapActions(['addBannerImages']),
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
