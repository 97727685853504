<template>
  <v-app>
    <!-- <v-overlay
      :z-index="100"
      :value="!appLoaded"
    >
      <v-progress-circular
        v-show="!appLoaded"
        indeterminate
        size="64"
      /> -->
    <home-system-bar />
    <!-- </v-overlay> -->

    <home-app-bar />

    <home-view @click="linkPressed" />

    <!-- <home-footer /> -->

    <home-settings />
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',
    components: {
      HomeAppBar: () => import('@/layouts/home/AppBar'),
      HomeSettings: () => import('@/layouts/home/Settings'),
      HomeSystemBar: () => import('@/layouts/home/SystemBar'),
      HomeView: () => import('@/layouts/home/View'),
    },
    data: () => ({
      appLoaded: false,
    }),
    mounted () {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
      // this.$vuetify.theme.dark = true;
      }
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
          this.appLoaded = true
        }
      })
    },
    methods: {
      linkPressed () {
        console.log('A thinkg happened')
      },
    },
  }
</script>
